import { features } from './features'; 

features.applicationType = 'viverogest';

features.fincaName = 'Invernadero';
features.fincasName = 'Invernaderos';

features.parcelaName = 'Carril';
features.parcelasName = 'Carriles';

features.hasClients = false;

features.showAnalisis = true;
features.showAnalisisParcelas = true; 
features.showCargaPAC = true;
features.showTractorMarcha = true;
features.showTractorRPM = true;
features.showTractorPresion = true;
features.showTractorBoquillas = true;
features.showVisitasAgenda = false; 
features.showVisitasConsultas = false;
features.showDesplazamientoObra = true;
features.showPartida = true;
features.puedeImprimir = true;
features.showAbonado = true;
features.showRegistroTratamiento = false;
features.showPhCaldo = true;
features.textoSuperficie = 'm²';
features.showEnviarWhatsapp = true;
features.showEnviarCorreo = true;
features.showEnviarWhatsappVisitas = true;
features.showEnviarCorreoVisitas = true;
features.showTareasBio = true;
features.showOrdenAplicacionProductos = true;
features.showMeteoBtn = true;
features.showRecetaGlobalGAP = true;
features.adminCanSeeAllRecords = true;
features.showVisitasUploadPhotos = true;
features.showMapasSatelite = false;
features.showMapaTratamientos = true;
features.showAnalisisSectorParcelas = true;
features.showTareasSiembra = true;
features.showRopoRomaRegepa = true;
features.showClientesRegepa = true;
features.showStock = false;
features.showStockTipoDeDato = false;
features.showStockProveedor = false;
features.showStockEnvase = false;
features.showReto = false;
features.showVentusky = true;
features.showFotoTrajador = true;
features.showVentasComercioProveedor = true;
features.showStockProveedorClienteDropdown = false;
features.showMapaGraniot = true;

features.showMapaLabels = true;

features.showMotivo = false;
features.showVisitasVariedades = true;
features.hiddenVisitasCerezo = true;
features.hiddenVisitasContador = true;
features.showVisitasFormAbonosFolques = false;
features.showVisitaCarenciasMultiple = false;

features.showUsuariosLogs = true;
features.showEstadisticas = false;
features.showVisitasSeguimiento = true;
features.showVisitas = true;

export const environment = {
    production: true,
    v: '2',
    serverUrl: 'https://viverospereira.viverogest.com/',
    logoUrl: 'assets/logo_viverospereira.png',
    appName: 'viverospereira',
    features: features
};