<br>

<div style="width: auto; overflow: auto">
    <app-common-list-table
            [request]="getRequest"
            [filtering]="filtering"
            [cols]="cols"
            [globalConditionalStyle]="globalConditionalStyle"
            [globalVisualTransform]="globalVisualTransform"
            actionButtonText="Añadir Edificio"
            (emitSearchText)="updateFooter()"
            (actionButtonClick)="add()">
            <ng-template #header>
                <div style="width: 100%; margin-right: 15px" class="d-flex justify-content-start align-items-center">
                    <div style="margin-left: 20px;">
                        <button class="mx-2 btn btn-info" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem;">
                            <i class="fa fa-download"></i>
                            Exportar listado
                        </button>
                    </div>
                    <div style="margin-left: 10px; margin-right: 10px">
                        <div style="font-size: 0.7em" class="btn-group">
                            <button (click)="filtrarRealizados('Realizada', 'prioridad');sum()"
                                [ngClass]="prioridad.selected === 'Realizada' ? 'btn btn-primary' : 'btn btn-light'" style="
                                                height: 28px;
                                                width: 40px;
                                                border: 1px solid grey;
                                                border-right: 1px solid grey">
                                SÍ
                            </button>
                            <button (click)="filtrarRealizados('No-Realizada', 'prioridad');sum()"
                                [ngClass]="prioridad.selected === 'No-Realizada' ? 'btn btn-primary' : 'btn btn-light'" style="
                                                height: 28px;
                                                width: 40px;
                                                border: 1px solid grey;
                                                border-right: 1px solid grey">
                                NO
                            </button>
                        </div>
                    </div>
                    <span style="color: white; margin-top: -0px;">
                        Realizados
                    </span>
                    <div style="margin-left: 20px ;display: flex;">
                        <app-datepicker (sendFecha)="getFechaInicio($event)">
                        </app-datepicker>
                        <label style="color:white; margin-left: 10px;margin-bottom: 0;">F.Inicio</label>
                    </div>
                    <div style="margin-left: 20px ;display: flex;">
                        <app-datepicker (sendFecha)="getFechaFin($event)">
                        </app-datepicker>
                        <label style="color:white; margin-left: 10px;margin-bottom: 0;">F.Fin</label>
                    </div>
                
                </div>
            </ng-template>
        <ng-template #thEnd>
            <th>
                <p class="d-flex justify-content-center"
                   style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons
                        [hideDelete]="hiddenButtonDelete" [showDuplicate]="showButtonDuplicate"
                        (duplicateRowButtonEvent)="duplicate(data)"
                        (editRowButtonEvent)="edit(data)"
                        (deleteRowButtonEvent)="show('delete', data)"
                        (showRowButtonEvent)="show('info', data)">
                </app-action-buttons>
            </td>

        </ng-template>
        <ng-template #footer let-col>
            <div style="font-size: 0.75em; text-align: left" *ngIf="(['cliente_nombre'].includes(col.field))">
              TOTAL:
            </div>
            <div *ngIf="(['horas_jardin'].includes(col.field))"
              style="font-size: 0.75em; text-align: right; font-weight: bold; padding-right: 34px">
              {{this.totalHorasJardin  || 0}}
            </div>
            <div *ngIf="(['horas_piscina'].includes(col.field))"
              style="font-size: 0.75em; text-align: right; font-weight: bold; padding-right: 34px">
              {{this.totalHorasPiscina  || 0}}
            </div>
        </ng-template>

    </app-common-list-table>
</div>

<p-dialog
    *ngIf="canShow['info']"
    header="Detalle del registro"
    [(visible)]="canShow['info']"
    (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <div style="display: flex;justify-content: space-between;">
            <h4>Datos de edificio</h4>
        </div>
        <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="2" 
        [fields]="formFields">
    </app-form-builder>
    <h5>Jardin</h5>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="2" 
        [fields]="formFieldsJardin">
    </app-form-builder>
    <h4>Piscina</h4>
    <h5>Horas</h5>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="2" 
        [fields]="formFieldsPiscinaHoras">
    </app-form-builder>
    <h5>Tareas</h5>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3" 
        [fields]="formFieldsPiscinaTareas01">
    </app-form-builder>
    <h6 class="edificios">Comprobar Parámetros</h6>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3" 
        [fields]="formFieldsPiscinaTareas02">
    </app-form-builder>
    <h6 class="edificios">Otras Tareas</h6>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3" 
        [fields]="formFieldsPiscinaTareas03">
    </app-form-builder>
    <h5>Productos</h5>
    <h6 class="edificios">Cloro</h6>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3" 
        [fields]="formFieldsPiscinaProductos01">
    </app-form-builder>
    <h6 class="edificios">Floculante</h6>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3" 
        [fields]="formFieldsPiscinaProductos02">
    </app-form-builder>
    <h6 class="edificios">Otros Productos</h6>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3" 
        [fields]="formFieldsPiscinaProductos03">
    </app-form-builder>
    <h5>Recambios</h5>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3" 
        [fields]="formFieldsPiscinaRecambios">
    </app-form-builder>
    <h4>Limpieza</h4>              
    <h5>Limpieza General</h5> 
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3" 
        [fields]="formFieldsLimpiezaGeneral">
    </app-form-builder>   
    <h5>Abrillantador Zaguanes</h5>
    <!-- <form-builder
        [model]="model"
        [distribution-order]="distributionOrder"
        [distribution-columns]="3" 
        [fields]="formFieldsLimpiezaZaguanes">
    </form-builder>
    <h5>Abrillantador Rellanos</h5>                    
    <form-builder
        [model]="model"
        [distribution-order]="distributionOrder"
        [distribution-columns]="3"
        [fields]="formFieldsLimpiezaRellanos">
    </form-builder>     -->
    <h4>Recambios</h4>
    <h5>Luces</h5>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3" 
        [fields]="formFieldsRecambiosLuces">
    </app-form-builder>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3" 
        [fields]="formFieldsRecambiosPapelHigienico">
    </app-form-builder>
    <h5>Papel Higienico</h5>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3" 
        [fields]="formFieldsRecambiosPapelHigienico">
    </app-form-builder>
    <h5>Rejillas</h5>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3" 
        [fields]="formFieldsRecambiosRejillas">
    </app-form-builder>
    <h5>Productos Limpieza</h5>
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="3" 
        [fields]="formFieldsRecambiosProductosLimpieza">
    </app-form-builder>
    <app-form-builder
        [model]="model"
        [distributionOrder]="[3]"
        [distributionColumns]="3" 
        [fields]="formFieldsTrabajadores">
    </app-form-builder>                 
    <div class="row">
        <div class="col-lg-6">
            <app-form-builder
            [model]="model"
            [distributionColumns]="1" 
            [fields]="formFieldsOrdenTrabajo">
        </app-form-builder>  
        </div>
        <div class="col-lg-6">
            <div style="display: flex;justify-content: space-between;">
                <app-upload-images 
                [model]="model" 
                folder="edificios" 
                tipo="list" 
                ></app-upload-images>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <app-form-builder
            [model]="model"
            [distributionColumns]="1" 
            [fields]="formFieldsObservaciones">
        </app-form-builder>  
        </div>
        <div class="col-lg-6">
            <div style="display: flex;justify-content: space-between;">
                <app-upload-images 
                [model]="model" 
                folder="edificios" 
                tipo="list"
                nombre_imagen = 'image_after'  
                ></app-upload-images>
            </div>
        </div>
    </div>
    
    <div>
        <div *ngIf="this.model['signature_img']" style="display: flex; flex-direction: column;">
            <label>Firma</label>
            <img [src]="serverUrl + 'images/edificios/'+this.model['signature_img'] " class="info-firma-img">
        </div>
    </div> 

        <div class="d-flex justify-content-center" style="margin-top: 40px">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>

</p-dialog>

<p-dialog
    *ngIf="canShow['delete']"
    header="Eliminar registro"
    [(visible)]="canShow['delete']"
    (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <div style="display: flex;justify-content: space-between;">
            <h4>Datos de edificio</h4>
        </div>
        <app-form-builder
                [fields]="formFields"
                [distributionColumns]="3"
                [distributionOrder]="distributionOrder"
                [model]="model">
        </app-form-builder>
        <br>
        <div style="display: flex; gap: 50px;margin-bottom: 100px; ">
            <div style="display: flex;justify-content: space-between;">
                <app-upload-images 
                [model]="model" 
                folder="edificios" 
                tipo="list" 
                ></app-upload-images>
            </div>
            <div style="display: flex;justify-content: space-between;">
        
                <app-upload-images 
                [model]="model" 
                folder="edificios" 
                tipo="list"
                nombre_imagen = 'image_after'  
                ></app-upload-images>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button
                    (click)="deleteRegister()"
                    [request]="deleteRequest"
                    [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
           style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>

</p-dialog>